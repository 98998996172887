<template>
  <div class="service-form-part">
    <MarketSegmentRadioField :choices="marketSegments" v-on="$listeners" />
    <h2 class="form-modal__part-title">
      {{ $t('field.type-of-assignment') }}
    </h2>
    <ServiceRadioField :choices="services" v-on="$listeners" />
  </div>
</template>

<script>
import {
  windowFramesAluminiumBE,
  windowFramesPlasticBE,
  windowFramesWoodBE,
} from 'chimera/windowFrames/service'
import { Selectable } from 'chimera/all/components/models/Selectable'
import MarketSegmentRadioField from 'chimera/all/components/form/fields/marketSegment/MarketSegmentRadioField.vue'
import ServiceRadioField from 'chimera/all/components/form/fields/service/ServiceRadioField.vue'

export default {
  name: 'ServiceRadioFormPart',

  components: {
    ServiceRadioField,
    MarketSegmentRadioField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      marketSegments: [
        new Selectable(
          'Woning',
          'Woning',
          this.$t('field.marketSegment.consumer'),
        ),
        new Selectable(
          'Zakelijk bedrijf',
          'Zakelijk bedrijf',
          this.$t('field.marketSegment.corporateValue'),
        ),
      ],

      services: [
        new Selectable(
          'Kunststof kozijnen',
          'Kunststof kozijnen',
          windowFramesPlasticBE.id,
        ),
        new Selectable(
          'Houten kozijnen',
          'Houten kozijnen',
          windowFramesWoodBE.id,
        ),
        new Selectable(
          'Aluminium kozijnen',
          'Aluminium kozijnen',
          windowFramesAluminiumBE.id,
        ),
      ],
    }
  },
}
</script>
